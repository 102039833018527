import * as React from 'react'
import Layout from 'components/layout'

import styled from 'styled-components'

const WistiaContent = styled.div`
	position: relative;
`

const BBResourceHub: React.FC = () => {
	return (
		<Layout
			title="Bid Board Pro Resource Hub | BuildingConnected"
			description="Watch videos about all things Bid Board Pro—including tutorials for getting started, feature walkthroughs, and customer testimonials."
		>
			<WistiaContent className="wistia_channel wistia_async_mobdgyg4x8 mode=inline" />
		</Layout>
	)
}

export default BBResourceHub
